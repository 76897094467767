<template>
    <div>
        <ul class="pagination justify-content-end">
            <li class="page-item"><span class="page-link" @click="pag_anterior">Anterior</span></li>
            <li v-if="this.paginacion.current_page > 5" class="page-item"><span class="page-link" @click="pag_especifica(1)">1</span></li>
            <li v-for="pagina in paginas" :class="'page-item'+es_activo(pagina)"><span class="page-link" @click="pag_especifica(pagina)">{{ pagina }}</span></li>
            <li v-if="this.paginacion.current_page <= (paginacion.last_page-5)" class="page-item"><span class="page-link" @click="pag_especifica(paginacion.last_page)">{{ paginacion.last_page }}</span></li>
            <li class="page-item"><span class="page-link" @click="pag_siguiente">Siguiente</span></li>
        </ul>
    </div>
</template>

<script>
export default {
    props: {
        paginacion: {
            type: Object,
            default() {
                return {
                    current_page: 1,
                    from:1,
                    last_page:1,
                    per_page: 20,
                    total: 200
                }
            }
        }
    }
    ,data() {
        return {
            
        }
    }
    ,methods: {
        es_activo(pag) {
            if (pag == this.paginacion.current_page)
                return ' active';
            
            return '';
        }
        ,pag_anterior() {
            if (this.paginacion.current_page > 1) {
                let nueva_paginacion = this.$helper.clone(this.paginacion);
                nueva_paginacion.current_page -= 1;

                this.$emit('update',nueva_paginacion);
            }
        }
        ,pag_siguiente() {
            if (this.paginacion.current_page < this.paginacion.last_page) {
                let nueva_paginacion = this.$helper.clone(this.paginacion);
                nueva_paginacion.current_page += 1;

                this.$emit('update',nueva_paginacion);
            }
        }
        ,pag_especifica(pag) {
            let nueva_paginacion = this.$helper.clone(this.paginacion);
            nueva_paginacion.current_page = pag;

            this.$emit('update',nueva_paginacion);
        }
    }
    ,computed: {
        paginas() {
            let paginas = [];

            let inicio = 1;

            if (this.paginacion.current_page <= 5)
                inicio = 1
            else
                inicio = this.paginacion.current_page - 4;

            let final = this.paginacion.current_page + 4;

            if (this.paginacion.current_page < (this.paginacion.last_page-4))
                final = this.paginacion.current_page+4;
            else
                final = this.paginacion.last_page;

            this.$log.info('inicio',inicio);
            this.$log.info('final',final);

            for(let i=inicio; i<=final; i++) {
                paginas.push(i);
            }

            return paginas;
        }
    }
}
</script>

<style lang="scss" scoped>
    .pagination {
        li {
            span {
                cursor: pointer;
            }
        }
    }
</style>